import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface LogsAndWoodchipProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
}));

const LogsAndWoodchip: React.FC<LogsAndWoodchipProps> = ({
  children,
  className,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Logs And Woodchip</h1>
      <p>
        <strong>Woodchip</strong> mulch can be used for woodland paths or to
        create a soft landing area for your children’s play area or horse riding
        arena. Suppresses weed growth in your flowerbeds. You will need 4 inches
        of mulch to completely block the light from the soil beneath.
      </p>
      <p>
        Our woodchip is a large chip this means the woodchip mulch will work
        better, look better, and last longer than what is available from other
        tree surgeons. Mulching simply means putting a layer of cover on the
        surface of the ground which will rot into the soil over a period of
        time. Wood chipping's are ideal for use around the garden as a mulch on
        your shrub beds.
      </p>
      <p>
        <strong>Logs</strong> - Timber from our tree surgery business is
        seasoned at our farm before cutting and splitting into logs. Remember
        burning logs for your heating is a carbon neutral fuel source using
        recycled timber, and if you buy 10 bags or more we will give you free
        delivery (local area) or an extra bag FOC if you collect!
      </p>
    </div>
  );
};

export default LogsAndWoodchip;
