// import everything we need
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import 'fontsource-roboto';
import MenuButton from '../Menu/MenuButton';
import SimpleDrawer from '../Drawer/SimpleDrawer';
import Typography from '@material-ui/core/Typography';

interface NavigationBarProps {
  children?: any;
  className?: string;
  id?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#535454',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 'auto',
  },
  // bottom of navigation
  AppBar: {
    width: '100%',
    background: '#535454',
  },
  // contains menu
  ToolBar: {
    background: '#535454',
    height: '20px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    height: '20px',
  },
  title: {
    flexGrow: 1,
  },
}));

const NavigationBar: React.FC<NavigationBarProps> = ({
  children,
  className,
  id,
  onClick,
}) => {
  const onMenuButtonClick = () => {
    console.log('onMenuButtonClick');
    setState(true);
  };

  const [state, setState] = React.useState(false);

  const handleClose = () => {
    setState(false);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.AppBar} position="static">
        <Toolbar className={classes.ToolBar}>
          <MenuButton
            // this button will activate the drawer men
            children={''}
            className={classes.menuButton}
            onClick={onMenuButtonClick}
            id="102"
          />
          <Typography>
            <h2>Menu</h2>
          </Typography>
        </Toolbar>
      </AppBar>

      <SimpleDrawer id={'103'} open={state} onClick={handleClose} />
    </div>
  );
};

export default NavigationBar;
