import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';

interface simAvatarProps {
    className?: string;
    alt: string;
    src: string;
    variant: "circle" | "square" | "rounded";
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    deepRed: {
        color: '#771818',
        backgroundColor: '#FFFFFF',
    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
}));

const SIMAvatar: React.FC<simAvatarProps> = (
    {
        alt,
        src,
        variant,
    }
) => {

    const classes = useStyles();

    return (
        <div className={classes.largeAvatar}>
            <Avatar
                className={classes.deepRed}
                alt={alt}
                src={src}
                variant={variant}>
                </Avatar>
        </div>
    );


};

export default SIMAvatar;