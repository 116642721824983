// import everything we need
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import 'typeface-roboto';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

interface AppMenuButtonProps {
  children?: any;
  className?: string;
  id: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  buttonEdge?: string;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
    textTransform: 'none',
  },
});

const MenuButton: React.FC<AppMenuButtonProps> = ({
  children,
  className,
  id,
  type = 'submit',
  onClick,
  disabled,
}) => {
  //   <span>{children}</span>
  const classes = useStyles();
  return (
    <IconButton
      className={classes.root}
      edge="start"
      color="inherit"
      aria-label="menu"
      id={id}
      type={type}
      onClick={onClick}
      //   className={clsx(classes.root, className)}
    >
      <MenuOpenIcon fontSize="large" />
    </IconButton>
  );
};

export default MenuButton;
