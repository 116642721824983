import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface StumpRemovalProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
}));

const StumpRemoval: React.FC<StumpRemovalProps> = ({
  children,
  className,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Stump Removal Page</h1>
      <p></p>
    </div>
  );
};

export default StumpRemoval;
