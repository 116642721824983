import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Banner from '../components/Banner/Banner';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Footer from '../components/Footer/Footer';

interface MainProps {
  children?: any;
  className?: string;
  id?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1200px',
    width: 'auto',
    height: 'auto',
    marginLeft: 'auto',
     //' theme.spacing(1),
    marginRight: 'auto', // theme.spacing(1),

  },
}));

const Main: React.FC<MainProps> = ({ children, className, id }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Banner
        className={'Banner'}
        autoPlay={true}
        interval={3000}
        indicators={true}
        animation={'fade'} //	"fade" | "slide"	"fade"
        timeout={100}
      />
      <NavigationBar />
      <Footer children={'Footer'} id={'102'} />
    </div>
  );
};

export default Main;
