import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

interface FooterProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'auto',
    height: '20px',
    background: '#535454',
    color: '#FCFCFC',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  p: { textAlign: 'right' },
}));

const Footer: React.FC<FooterProps> = ({ children, className, id }) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Box></Box>
    </div>
  );
};

export default Footer;
