import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import AlarmIcon from '@material-ui/icons/Alarm';
import HomeIcon from '@material-ui/icons/Home';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LeakRemoveIcon from '@material-ui/icons/LeakRemove';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DrawerItem from '../Drawer/DrawerItem';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
  })
);

const appMenuItems = [
  {
    name: 'About Us',
    link: '/AboutUs',
    Icon: HomeIcon,
  },
  {
    name: 'Contact Us',
    link: '/ContactUs',
    Icon: AddIcCallIcon,
  },
  {
    name: 'Emergency Tree Surgery',
    link: '/EmergencyTreeSurgery',
    Icon: AlarmIcon,
  },
  {
    name: 'Commercial Contracts',
    link: '/CommercialContracts',
    Icon: PermContactCalendarIcon,
  },
  {
    name: 'Stump Removal',
    link: '/StumpRemoval',
    Icon: NaturePeopleIcon,
  },
  {
    name: 'Site Clearance',
    link: '/SiteClearance',
    Icon: LeakRemoveIcon,
  },
  {
    name: 'Logs And Woodchip',
    link: '/LogsAndWoodchip',
    Icon: FireplaceIcon,
  },
  {
    name: 'Frequently Asked',
    link: '/FrequentlyAsked',
    Icon: LiveHelpIcon,
  },
];

const Menu: React.FC = () => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <DrawerItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <DrawerItem {...item} key={index} />
      ))}
    </List>
  );
};

export default Menu;
