import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface SiteClearanceProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
}));

const SiteClearance: React.FC<SiteClearanceProps> = ({
  children,
  className,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Site Clearance Page</h1>
    </div>
  );
};

export default SiteClearance;
