import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface EmergencyTreeSurgeryProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
}));

const EmergencyTreeSurgery: React.FC<EmergencyTreeSurgeryProps> = ({
  children,
  className,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Emergency Tree Surgery Page</h1>
      <p></p>
    </div>
  );
};

export default EmergencyTreeSurgery;
