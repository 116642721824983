import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
//import withStyles from '@material-ui/core/styles/withStyles';
import Carousel from 'react-material-ui-carousel';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { BannerType } from './BannerInterface';
// import Media from 'react-media';
import SIMAvatar from '../Avatar/Avatar';
// Images
import ImgSrc from '../images/fallentree.jpg';
// Avatar
import EmergencyAvatar from '../images/icons8-last-24-hours-96.png';
import FireAvatar from '../images/icons8-fire-90.png';
import TreeAvatar from '../images/icons8-oak-tree-96.png';
// import ResumeAvatar from '../images/icons8-resume-96.png';
import CertificateAvatar from '../images/icons8-certificate-96.png';




interface BannerProps {
  className?: string;
  autoPlay: boolean;
  interval: number; // 4000
  indicators: boolean;
  animation: 'fade' | 'slide'; //	"fade" | "slide"	"fade"
  timeout: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: '460px',
    color: '#494949',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  bannerRed: {
    color: '#FFFFFF',
    backgroundColor: '#771818',
    height: '420px',
    position: 'relative',
  },
  bannerGreen: {
    color: '#FFFFFF',
    backgroundColor: '#113322',
    height: '420px',
    position: 'relative',
  },
  bannerOrange: {
    color: '#FFFFFF',
    backgroundColor: '#998322',
    height: '420px',
    position: 'relative',
  },
  bannerBlue: {
    color: '#FFFFFF',
    backgroundColor: '#35578f',
    height: '420px',
    position: 'relative',
  },
  // contents
  contentRed: {
    color: '#FFFFFF',
    backgroundColor: '#771818', // dark red
    height: '20%',
//    cursor: 'pointer',
    //padding: '4px',
    transition: '100ms',
  },
  contentGreen: {
    color: '#FFFFFF',
    backgroundColor: '#113322', // dark red
    height: '20%',
   // cursor: 'pointer',
   // padding: '10px',
    transition: '100ms',
  },
  contentOrange: {
    color: '#FFFFFF',
    backgroundColor: '#998322', // dark red
    height: '20%',
   // cursor: 'pointer',
   // padding: '10px',
    transition: '100ms',
  },
  contentBlue: {
    color: '#FFFFFF',
    backgroundColor: '#35578f', // dark red
    height: '20%',
   //  cursor: 'pointer',
   //  padding: '10px',
    transition: '100ms',
  },







  carousel: {
    height: '420px',
    color: '#773344',
    position: 'relative',
  },
  media: {
    //  backgroundColor: '#FF66cc',
    height: '280px',
    objectFit: 'scale-down',
    width: 'auto',
    maxWidth: '1200px',
    overflow: 'hidden',
    position: 'relative',
  },

  mediaCaption: {
    // display label under image
    textOverflow: 'ellipsis',
    position: 'absolute',
    bottom: '0',
    left: '0',
    padding: '6px',
  ///  backgroundColor: '#2277aa',
    color: 'white',
    opacity: '0.6',
    width: '100%',
    height: '100%',
    fontSize: '140%',
  },
  bannerGrid: {
    height: '100%',
  },
  font: {
    size: '120%',
    weight: '200',
    // transition: '300ms',
    cursor: 'pointer',
    //hover { opacity: '0.8'}
    // }
    //   &:hover {
    //   opacity: '0.8',
    // }
  },
  header: {
    height: '10px',
  },
  content: {
    color: '#FFFFFF',
    backgroundColor: '#771818', // dark red
    height: '100%',
    cursor: 'pointer',
    padding: '10px',
    transition: '100ms',
  },
  title: {
    size: '140%',
    weight: '500',
  },
  caption: {
    marginTop: '10px',
    size: '140%',
  },
  avatar: {
    backgroundColor: '#771818',
  },
  cardHeader: {
    marginTop: '12px',
    height: '56px',
  }
}));

const Banner: React.FC<BannerProps> = (
  {
    // className,
    autoPlay,
    interval,
    indicators,
    animation,
    timeout,
  }
) => {

  const classes = useStyles();

  const items: BannerType[] = [
    {
      contentHeader: '24-7 Emergency Service Call Out',
      Name: 'Commercial and Domestic',
      Caption: 'Tel: 01706 658771',
      contentPosition: 'left',
      Items: [
        {
          itemsName: 'Tel: 01706 658771',
          itemsSmallImage: ImgSrc,
          itemsMediumImage: ImgSrc,
          itemsLargeImage: ImgSrc,
          itemsAvatarImage: EmergencyAvatar,
          itemsAvatarAltText: 'Rochdale, North West',
          itemBannerStyle: classes.bannerRed,
          itemContentStyle: classes.contentRed,
        },
      ],
    },
    {
      contentHeader: 'Kiln Dried Logs, Chippings and Bark',
      Name: 'Free Delivery',
      Caption: 'Tel: 01706 658771',
      contentPosition: 'left',
      Items: [
        {
          itemsName: 'Tel: 01706 658771',
          itemsSmallImage: ImgSrc,
          itemsMediumImage: ImgSrc,
          itemsLargeImage: ImgSrc,
          itemsAvatarImage: FireAvatar,
          itemsAvatarAltText: 'Logs and Chippings',
          itemBannerStyle: classes.bannerGreen,
          itemContentStyle: classes.contentGreen,
        },
      ],
    },
    {
      contentHeader: 'Stump Grinding, Removal and Site Clearance',
      Name: 'Free Quote',
      Caption: 'Tel: 01706 658771',
      contentPosition: 'left',
      Items: [
        {
          itemsName: 'Tel: 01706 658771',
          itemsSmallImage: ImgSrc,
          itemsMediumImage: ImgSrc,
          itemsLargeImage: ImgSrc,
          itemsAvatarImage: TreeAvatar,
          itemsAvatarAltText: 'Stump Grinding',
          itemBannerStyle: classes.bannerOrange,
          itemContentStyle: classes.contentOrange,
        },
      ],
    },
    {
      contentHeader: 'City & Guilds NPTC Qualified, Fully Insured',
      Name: 'Commercial and Domestic',
      Caption: 'Tel: 01706 658771',
      contentPosition: 'left',
      Items: [
        {
          itemsName: 'Tree Surgery Rochdale',
          itemsSmallImage: ImgSrc,
          itemsMediumImage: ImgSrc,
          itemsLargeImage: ImgSrc,
          itemsAvatarImage: CertificateAvatar,
          itemsAvatarAltText: 'Tree Surgery City & Guilds NPTC Qualified',
          itemBannerStyle: classes.bannerBlue,
          itemContentStyle: classes.contentBlue,
        },
      ],
    },
  ];



  /*
  function BannerImage (a: BannerItems[]) {

    <Media
    queries={{
      small: '(max-width: 599px)',
      medium: '(min-width: 600px) and (max-width: 1199px)',
      large: '(min-width: 1200px)',
    }}
  >
    {(matches) =>
      matches.small ? (
        thisImageName = thisSmallImage
      ) : matches.medium ? (
        thisImageName = thisMediumImage
      ) : matches.large ? (
        thisImageName = thisLargeImage
      ) : (
        thisImageName = thisLargeImage
      )
    }
  </Media>

     return a.Items[0].itemsName;;
  }
  */


  function BannerNode(a: BannerType) {

    let items = [];
    const i: number = 0;
    const thisItemName: string = a.Items[i].itemsName;
    const thisSmallImage: string = a.Items[i].itemsSmallImage;
    /**  const thisMediumImage: string = a.Items[i].itemsMediumImage;
      const thisLargeImage: string = a.Items[i].itemsLargeImage;
    */
    const thisAvatarImage: string = a.Items[i].itemsAvatarImage;
    const thisAvatarAlText: string = a.Items[i].itemsAvatarAltText;
    const thisBannerClass: string = a.Items[i].itemBannerStyle;
    const thisContentlass: string = a.Items[i].itemContentStyle;

    const mediaImage = (
      <Card raised className={thisBannerClass}>

        <CardHeader className={classes.cardHeader}
          avatar={
            <SIMAvatar src={thisAvatarImage} alt={thisAvatarAlText} variant={"rounded"}></SIMAvatar>
          }

          title={<Typography gutterBottom variant="h5" component="h2">{a.contentHeader}</Typography>}
        // subheader="September 14, 2016"
        ></CardHeader>


        <CardMedia
          className={classes.media}
          image={thisSmallImage}
        >

        </CardMedia>

        <CardContent className={thisContentlass}>
          {/*  <Typography className={classes.title}>{a.contentHeader}</Typography> */}
          <Typography gutterBottom variant="h5" component="h2"> {a.Name + ' ' + a.Caption}</Typography>
          {/* <Typography className={classes.title}>{a.Caption}</Typography> */}
        </CardContent>
      </Card>
    );


    items.push(mediaImage);
    //  items.push(content);

    return (
      <div>
        {items}
      </div>

    );
  }

  return (
    <div className={classes.root}>
      <Carousel
        //className={classes.carousel},
        autoPlay={autoPlay}
        interval={interval}
        indicators={indicators}
        animation={animation}
        timeout={timeout}
      >
        {items.map((item, index) => {
          return (
            <BannerNode
              {...item}
              key={index}
              contentPosition={item.contentPosition}
            />
          );
        })}
      </Carousel>
    </div>
  );


};

export default Banner;

/*
          avatar={
            <Avatar src={EmergencyAvatar} alt={'boo'}></Avatar>
          }
          //   avatar={
          //   <Avatar className={classes.largeAvatar} {EmergencyAvatar} aria-label="recipe">

          // </Avatar>
          //   }
          */