import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface AboutUsProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
    size: '120%',
    weight: '200',
  },
}));

const AboutUs: React.FC<AboutUsProps> = ({ children, className, id }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>About Us Page</h1>
      <p>
        <strong>Special Branch Tree Surgery </strong>are Professional
        Arboricultural Tree Surgeons. We provide a very high level of service to
        all our customers whether large or small.
      </p>
      <p>
        For <i>friendly, expert advice</i> and a <i>free no obligation quote</i>{' '}
        please call Special Branch Tree Surgery - tel:{' '}
        <strong>
          <a href="tel:01706658771">01706 658771</a>
        </strong>
      </p>
      <p>
        The knowledge and experience allows us to excel in our field of
        expertise, all our operatives are:
      </p>
    </div>
  );
};

export default AboutUs;
