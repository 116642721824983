import React from 'react';
import clsx from 'clsx';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Menu from '../Menu/Menu';
import LogsAndWoodchip from '../../containers/LogsAndWoodchip';
import SiteClearance from '../../containers/SiteClearance';
import EmergencyTreeSurgery from '../../containers/EmergencyTreeSurgery';
import CommercialContracts from '../../containers/CommercialContracts';
import StumpRemoval from '../../containers/StumpRemoval';
import FrequentlyAsked from '../../containers/FrequentlyAsked';
import ContactUs from '../../containers/ContactUs';
import AboutUs from '../../containers/AboutUs';

const PageEmergencyTreeSurgery = () => (
  <EmergencyTreeSurgery children={'Header'} id={'101'} />
);
const PageCommercialContracts = () => (
  <CommercialContracts children={'Header'} id={'102'} />
);
const PageStumpRemoval = () => <StumpRemoval children={'Header'} id={'102'} />;
const PageSiteClearance = () => (
  <SiteClearance children={'Header'} id={'101'} />
);
const PageLogsAndWoodchip = () => (
  <LogsAndWoodchip children={'Header'} id={'101'} />
);
const PageFrequentlyAsked = () => (
  <FrequentlyAsked children={'Header'} id={'101'} />
);
const PageContactUs = () => <ContactUs children={'Header'} id={'101'} />;
const PageAboutUs = () => <AboutUs children={'Header'} id={'101'} />;

interface SimpleDrawerProps {
  children?: any;
  className?: string;
  id: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  open: boolean;
}

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#535454',
    color: '#fff',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    width: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const SimpleDrawer: React.FC<SimpleDrawerProps> = ({
  children,
  className,
  id,
  type = 'submit',
  onClick,
  open,
}) => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={clsx('App', classes.root)}>
        <CssBaseline />
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={open}
          onClick={onClick}
        >
          <Menu />
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="xl" className={classes.container}>
            <Switch>
              <Route
                path="/EmergencyTreeSurgery"
                exact
                component={PageEmergencyTreeSurgery}
              />
              <Route
                path="/CommercialContracts"
                exact
                component={PageCommercialContracts}
              />
              <Route path="/StumpRemoval" exact component={PageStumpRemoval} />
              <Route
                path="/SiteClearance"
                exact
                component={PageSiteClearance}
              />
              <Route
                path="/LogsAndWoodchip"
                exact
                component={PageLogsAndWoodchip}
              />
              <Route
                path="/LogsAndWoodchip"
                exact
                component={PageSiteClearance}
              />
              <Route
                path="/FrequentlyAsked"
                exact
                component={PageFrequentlyAsked}
              />
              <Route path="/ContactUs" exact component={PageContactUs} />
              <Route path="/AboutUs" exact component={PageAboutUs} />
              <Route path="*" component={PageAboutUs} />
            </Switch>
          </Container>
        </main>
      </div>
    </BrowserRouter>
  );
};

/*      <Route path="/" exact component={PageDashboard} />
              <Route path="/" exact component={PageDashboard} />
              <Route path="/" exact component={PageDashboard} />
              <Route path="/" exact component={PageDashboard} />

              <Route path="/" exact component={PageDashboard} />
              <Route path="/orders" component={PageOrders} />
              <Route path="/customers" component={PageCustomers} />
              <Route path="/reports" component={PageReports} />
        */

export default SimpleDrawer;
