import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface FrequentlyAskedProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
    size: '120%',
    weight: '200',
  },
}));

const FrequentlyAsked: React.FC<FrequentlyAskedProps> = ({
  children,
  className,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Frequently Asked</h1>
      <p>
        To assist you we have put together a Q &amp; A Frequently asked
        questions section
      </p>
      <p>
        <h4>What happens to the chippings that are left?</h4>
        Stump grinders reduce the stump to chippings that are mixed up with the
        soil in which the stump was standing. This valuable resulting 'mulch' is
        left in the tree stump hole for our customer to utilise. It can also be
        used as a mulch elsewhere. The ground can then be replanted immediately,
        turfed or the mix can be used as an excellent alternative to other forms
        of weed inhibiting mulch.
      </p>
      <p>
        <h4>How much does it cost?</h4>
        Costs are based upon the size and number of stumps to be removed. When
        asking for a quotation it would be useful to have some idea of the
        number of stumps and a rough idea of how wide they are and how tall the
        stump is. Check the available access. This information should enable
        Special Branch to give you an accurate, 'no obligation' quotation on the
        phone.
      </p>
      <p>
        <h4>How soon can you come to remove my stump?</h4>
        Special Branch try to be as flexible as we can. Work will usually be
        booked to be done within two weeks depending on the time of year.
      </p>
      <p>
        <h4>Do I need to be at home?</h4>
        Special Branch like to meet their customers to make sure they are 100%
        satisfied with the job that’s been completed. Unfortunately, due to our
        customer's busy lives, we don’t get to meet everyone, but our reputation
        is such that customers are happy for us to carry out the work in their
        absence knowing that the work will be done efficiently with the minimum
        amount of ground disturbance and the area being left tidy.
      </p>
      <p>
        <h4>Will the tree re-grow?</h4>
        Usually any stump that Special Branch remove is completely ground away
        and will not regrow. There are one or two exceptions, willow for
        example, that may send up the odd shoot from a piece of root but even
        these can easily be managed with advice from Special Branch.
      </p>
      <p>
        <h4>I have a stump that is right up against a wall?</h4>
        If this is the case, then Special Branch have the answer. Our versatile
        machinery used is ideal for inching right up to a wall, patio or other
        object that you do not want to have damaged in any way. Special Branch
        can usually take out all signs of a stump literally to within
        millimetres of any structure without causing damage.
      </p>
      <p>
        <h4>How much mess will be left?</h4>
        Special Branch pride ourselves in providing a service that leaves the
        customer completely happy with the work carried out. This includes
        leaving the area tidy after the operation is complete. Special Branch
        use shields and boards to prevent the chippings going where they are not
        wanted.
      </p>
      <p>
        <h4>My stump is in the back garden?</h4>
        We use versatile equipment, which is motorised, and self-propelled but
        we do need access, a standard 30" garden gate is usually sufficient. If
        there are tight twists and turns, steps or very steep banking’s, please
        ring to discuss access.
      </p>
    </div>
  );
};

export default FrequentlyAsked;
