import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface ContactUsProps {
  children: any;
  className?: string;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
}));

const ContactUs: React.FC<ContactUsProps> = ({ children, className, id }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Contact Us Page</h1>
      <p></p>
    </div>
  );
};

export default ContactUs;
